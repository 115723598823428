<template>
  <div class="home">
    <!-- <filter-bar></filter-bar> -->
    <div class="projects-overview">
      <about></about>
      <!-- <filter-bar/> -->

      
      <div v-for="p in projects" :key="p.id">
        <overview-project :project-id="p.id" :project-slug="p.slug" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import ProjectAttract from '@/components/ProjectAttract.vue'
import dataImport from '../model/dataImport'
// import FilterBar from '../components/FilterBar.vue'
import About from '../components/About.vue'
import OverviewProject from '../components/OverviewProject.vue'
// import FilterBar from '../components/FilterBar.vue'
// import animejs from 'animejs'

export default {
  name: 'Home',
  components: {
    // ProjectAttract
    // TitleBar,
    OverviewProject,
    About,
    // FilterBar
  },
  data: function () {
    return {
      projectData: dataImport.data.list
    }
  },
  computed: {
    projects () {
      const dat = this.projectData.slice(0).sort(function (a, b) {
        return parseFloat(a.order) - parseFloat(b.order)
      })
      return dat
    }
  },
  mounted: function () {
    // console.log(this.projectData)
  },
  created: function () {
    document.title = "Cas Spoelstra - Portfolio"
  },

  methods: {
    map: (value, x1, y1, x2, y2) => {
      (value - x1) * (y2 - x2) / (y1 - x1) + x2
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/style/global.scss';
.projects-overview {
  padding-bottom: 6em;
  max-width: 1080px;
  margin: 0 auto;
}

@media (min-width: 2080px) {
  .projects-overview {
    max-width: 99999px;
    columns: 2;
    padding: 0 5%;
  }
  .overview-project-container {
    // background-color: green;
    display: inline-block;
    max-width: 1032px;
  }
}



</style>
