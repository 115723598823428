<template>
  <transition v-on:leave="leave" v-on:enter="enter">
    <div class="overview-project-container" :id="`project-id-${projectId}`" v-show="filterShow" :class="{'isDev': this.projectData.category === 'dev', 'isId': this.projectData.category === 'interactionDesign'}">
      <router-link :to="`/project/${this.projectData.slug}`">
        <div class="project-preview-title">
          <dot-label :color="this.projectData.category" :text="this.projectData.name" :isTitle="true"></dot-label>
          <!-- <div class="actual-title">
            {{this.projectData.name}}
          </div> -->
          <!-- <div class="dot-behind"></div> -->
        </div>
        <div class="project-preview-thumb">
          <img v-if="!loaded" class="low-res-image" :src="`./static/images/${this.employerSignifier}${this.projectData.slug}/LR/${this.projectData.thumb.filename}.jpg`"/>
          <picture :class="{'isDev' :this.projectData.employer}" v-show="loaded" :id="`image-thumb-${projectId}`">
            <source :srcset="`./static/images/${this.employerSignifier}${this.projectData.slug}/${this.projectData.thumb.filename}.webp`" type="image/webp">
            <source :srcset="`./static/images/${this.employerSignifier}${this.projectData.slug}/${this.projectData.thumb.filename}.jpg`" type="image/jpeg">
            <img    :src="`./static/images/${this.employerSignifier}${this.projectData.slug}/${this.projectData.thumb.filename}.jpg`"/>
            <!-- <img v-show="loaded" :id="`image-thumb-${projectId}`" :src="`./static/images/${this.employerSignifier}${this.projectData.slug}/${this.projectData.thumb.filename}`"/> -->
          </picture>
        </div>
        <div class="project-preview-sub-container">
          <div class="project-preview-date">{{this.projectData.date}}</div>
          <div class="project-preview-category">
            <div v-if="this.projectData.category === 'interactionDesign'">Interaction Design</div>
            <div v-if="this.projectData.category === 'dev'">Development & UX</div>
          </div>
        </div>
      </router-link>
    </div>
  </transition>
</template>

<script>
import animejs from 'animejs'

import DotLabel from './DotLabel.vue'
import dataImport from '../model/dataImport'
export default {
  components: { DotLabel },  
  name: 'OverviewProject',
  props: {
    projectSlug: String,
    projectId: Number
  },
  computed: {
    prettyDate () {
      const last2Numbers = this.projectData.date - 2000
      return `'${last2Numbers}`
    },
    employerSignifier () {
      if (this.projectData.employer != null) {
        return this.projectData.employer + '/'
      } else {
        return ''
      }
    },
    filterShow () {
      if (this.projectData.employer != null) {
        return this.$store.state.filter.dev
      } else {
        return this.$store.state.filter.id
      }



      // if (this.projectData.employer != null && this.$store.state.filter.dev == true) {
      //   return false
      // } else if (this.projectData.employer != null && this.$store.state.filter.id == true) {
      //   return false
      // } else {
      //   return true
      // }
    }
  },
  data: function () {
    return {
      projectData: dataImport.getBySlug(this.projectSlug),
      loaded: false,
    }
  },
  mounted: function () {
    const container = this.$el.getElementsByClassName('project-preview-thumb')[0]
    const paddingAmount = (this.projectData.thumb.dimensions.y / this.projectData.thumb.dimensions.x * 100) * 0.9
    container.style.paddingBottom = `${paddingAmount}%`

    const image = document.getElementById(`image-thumb-${this.projectId}`).getElementsByTagName('img')[0]
    image.addEventListener('load', () => {
      this.loaded = true;
    })
  },
  methods: {
    leave (el, done) {
      animejs({
        targets: el,
        translateX: ['0px', '-200px'],
        scaleY: [1, 0],
        // height: 0,
        marginTop: 0,
        duration: 2000,
        easing: 'linear',
        complete: () => {
          done()
        }
      })
    },
    enter (el, done) {
      animejs({
        targets: el,
        translateX: ['-200px', '0px'],
        scaleY: [0, 1],
        height: ['0%', '100%'],
        marginTop: ['0em', '5em'],
        duration: 2000,
        opacity: [0, 1],
        easing: 'linear',
        complete: () => {
          done()
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';

.hidden {
  opacity: 0;
  height: 0;
  display: none !important; 
}

.overview-project-container {
  display: block;
  width: 90%;
  margin: 5em auto 0;
  // border-bottom: 1px solid lightgrey;
  // padding-bottom: 5em;
  // &:last-child {
  //   padding-bottom: 0;
  //   border: none;
  // }
  a {
    text-decoration: none;
    color: black;
  }
}

.project-preview-title {
  text-align: center;
  font-weight: 700;
  position: relative;
  margin-bottom: 1em;
}

.dot-behind {
  width: 40px;
  height: 40px;
  background-color: green;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}

.project-preview-thumb {
  position: relative;
  width: 90%;
  background-color: lightgrey;
  margin: 0em auto 0.5em;
  border: 3px solid white;
  line-height: 0;
  overflow: hidden;
  box-sizing: content-box;
  & img {
    position: absolute;
    line-height: 0;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0 auto;
  }
}

.overview-project-container.isDev .project-preview-thumb {
  outline: 4px solid $c-yellow;
}
.overview-project-container.isId .project-preview-thumb {
  outline: 4px solid $c-lime;
}



.low-res-image {
  display: block;
  filter: blur(20px);
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.project-preview-sub-container {
  width: 90%;
  // display: flex;
  margin: 0.5em auto 0;
}

.project-preview-date {
  flex-grow: 1;
  display: inline-block;
  font-size: 1.5em;
  text-align: left;
  font-weight: 700;
}

.project-preview-category {
  flex-grow: 1;
  display: inline-block;
  margin-left: 10px;
  // text-align: right;
}

@media (min-width: 1080px) {
  .project-preview-thumb {
    // border: 7px solid white;
    // outline: 7px solid $c-lime;
  }
}

@media (min-width: 1080px) and (max-width: 2080px) {
.overview-project-container {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 5em;
    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }
}



// .overview-project-container {
//   border-bottom: 1px solid lightgrey;
//   padding-bottom: 5em;
//   &:last-child {
//     padding-bottom: 0;
//     border: none;
//   }
// }

@media (prefers-color-scheme: dark) {
  .project-preview-thumb {
    border-color: $c-d-bg;
  }
  .overview-project-container a {
    color: $c-d-text;
  }
  .overview-project-container.isDev .project-preview-thumb {
    outline: 4px solid $c-d-yellow;
  }
  .overview-project-container.isId .project-preview-thumb {
    outline: 4px solid $c-d-lime;
  }
}
</style>