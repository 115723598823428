<template>
  <div class="about-wrapper">
      <div class="about-container">
        <div class="about-content">
          <p>
            This is the portfolio of Cas Spoelstra (1988).<br/> I am an <span class="about-id about-type color-lime" @click="scrollToId">Interaction Designer</span> & <span class="about-dev color-yellow about-type" @click="scrollToDev">Software Developer and UX Designer</span>
          </p>
          <p>
            This website shows selected works.
          </p>
          <p>
            My interaction design work focuses mostly on translating and deciphering opaque and complex systems into transparent constructions.
          </p>
          <p>
            I communicate through my designs the mechanics of such systems by extricating a specific aspect. Using a raw aesthetic the complexity is condensed into a comprehensible form to facilitate critical thought. 
          </p>
          <p>
            You can mail me at: <span class="email" @click="mail">cas{{'@'}}casspoelstra{{'.'}}nl</span>
          </p>
        </div>
      </div>
  </div>
</template>

<script>
// import animejs from 'animejs'

export default {
  name: 'About',
  data: function () {
    return {
      content: null,
    }
  },
  methods: {
    scrollToId: function () {
      const element = document.getElementsByClassName('isId')[0]
      const yOffset = (document.getElementsByClassName('title-bar')[0].getBoundingClientRect().bottom * -1) - 10;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scroll({
        top: y,
        behavior: 'smooth'
      })
    },
    scrollToDev: function () {
      const element = document.getElementsByClassName('isDev')[0]
      const yOffset = (document.getElementsByClassName('title-bar')[0].getBoundingClientRect().bottom * -1) - 10;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scroll({
        top: y,
        behavior: 'smooth'
      })
    },
    mail: function () {
      const l = 'cas@'
      const m = 'casspo'
      const a = 'elstra'
      const o = '.nl'
      location.href = `mailto:${l}${m}${a}${o}`
    }
  },
  computed: {
  },
  mounted: function () {
  }
}
</script>

<style scoped lang="scss">
  @import '@/style/vars.scss';

  .about-wrapper {
    width: 100%;
    margin: 0 auto;
  }

  .about-container {
    position: relative;
    color: $c-red;
    text-align: left;
    font-size: 0.8em;
    width: 80%;
    margin: 0 auto;
    padding: 0.1em 2em;
    line-height: 1.3;
    border: 2px solid $c-red;
    overflow: hidden;
  }

  .about-content {
    line-height: 1.5;
  }

  .about-type {
    color: black;
    padding: 0.1em 0.3em;
    cursor: pointer;
    line-height: 1.8;
  }
  // .about-id {
  //   background-color: $c-lime;
  // }

  // .about-dev {
  //   background-color: $c-yellow;
  // }

  .email {
    color: black;
  }

  @media (min-width: 2080px) {
    .about-wrapper {
      width: 90%;
      margin: 0;
    }
  }

  @media (prefers-color-scheme: dark) {
    .about-type {
      color: black;
    }
    .email {
      color: $c-d-text;
    }
  }
</style>